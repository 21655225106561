import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import default_seo_image from '../../gfx/website-image.png'

import { get } from 'lodash'

function SEO({ description, path, lang, meta, keywords, title, frontmatter }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description ||
          get(frontmatter, 'seo.description', null) ||
          data.site.siteMetadata.description

        const metaTitle =
          title ||
          get(frontmatter, 'seo.title', null) ||
          get(frontmatter, 'title', null) ||
          data.site.siteMetadata.title

        const { siteUrl, title: siteTitle } = data.site.siteMetadata

        const ogImage =
          get(frontmatter, 'seo.image.childImageSharp.og.src', null) ||
          get(frontmatter, 'cover_image.childImageSharp.og.src', null)

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={metaTitle}
            titleTemplate={`%s | ${siteTitle}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: metaTitle,
              },
              {
                property: `og:image`,
                content: ogImage? `${siteUrl}${ogImage}` : default_seo_image,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:url`,
                content: `${siteUrl}${path}`,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: metaTitle,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
  path: PropTypes.string.isRequired,
  frontmatter: PropTypes.object,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
  }
`

// would like to make this more DRY but can't have graphql fragments on multiple types yet, so needs to be copied for MDX support
// keep in mind when making edits to the query to do them twice.
export const SEOQuery = graphql`
  fragment SEOImage on File {
    childImageSharp {
      og: resize(width: 1200, height: 630) {
        src
      }
    }
  }

  fragment SEO on MarkdownRemark {
    excerpt
    fields {
      slug
    }
    frontmatter {
      title
      cover_image {
        ...SEOImage
      }
      seo {
        image {
          ...SEOImage
        }
        title
        description
      }
    }
  }
  fragment SEOMDX on Mdx {
    excerpt
    fields {
      slug
    }
    frontmatter {
      title
      cover_image {
        ...SEOImage
      }
      seo {
        image {
          ...SEOImage
        }
        title
        description
      }
    }
  }
`
